import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavBarItemList } from "common/types/mobile-navbar";
import LazyImage from "components/LazyImage";
import { MainCategoryItem } from "modules/category/types";
import { FC, useMemo } from "react";

type Props = {
  // Omit icon prop type from MainCategoryItem to prevent ts error
  item?: NavBarItemList & Omit<MainCategoryItem, 'icon'>;
  key?: string;
  isMainCateg?: boolean;
  onCategoryClick?: (mainCateg: string) => void;
};

export const MainMenuDrawerItem: FC<Props> = ({
  item,
  key,
  isMainCateg = false,
  onCategoryClick,
}) => {
  const iconSRC = useMemo(
    () => (isMainCateg ? item.icon_sidedrawer : item.icon),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMainCateg]
  );

  if (isMainCateg) {
    return item?.is_visible_sidedrawer ? (
      <ListItemButton key={key} onClick={() => onCategoryClick(item.name)}>
        <ListItemIcon>
          <LazyImage
            width={24}
            height={24}
            objectFit="contain"
            src={iconSRC || "/assets/images/sad_red_hair.png"}
            alt="kachi"
          />
        </ListItemIcon>
        <ListItemText sx={{ opacity: 0.8 }}>{item.name}</ListItemText>
      </ListItemButton>
    ) : null;
  }

  return (
    <ListItemButton href={item.href} key={key} onClick={item.onClick}>
      <ListItemIcon>
        <item.icon />
      </ListItemIcon>
      <ListItemText sx={{ opacity: 0.8 }}>{item.title}</ListItemText>
    </ListItemButton>
  );
};
