import { pcwEvent } from "common/constants/pcw-event";
import Footer from "components/footer/Footer";
import DeliveryHeader from "components/header/DeliveryHeader";
import Header from "components/header/Header";
import MobileNavigationBar from "components/mobile-navigation/MobileNavigationBar";
import Sticky from "components/sticky/Sticky";
import Topbar from "components/topbar/Topbar";
import useWindowSize from "hooks/useWindowSize";
import Head from "next/head";
import React, { FC, Fragment, useEffect, useMemo, useState } from "react";
import MobileMenuDrawer from "components/mobile-navigation/MobileMenuDrawer";
import { BranchMenu } from "components/branch/BranchMenu";
import { useOpacity } from "hooks/useOpacity";
import { useIsMobile } from "hooks";
import { useMainCategory } from "hooks/useMainCategory";
import { MobileSubCategDrawer } from "components/mobile-navigation/MobileSubCategDrawer";
import { useRouter } from "next/router";
import { navigationRoutes } from "common/constants/navigation-routes";

// ===================================================
type ShopLayout1Props = {
  title?: string;
  hasSearch?: boolean;
  navbar?: React.ReactChild;
  onClickNavigateToCartPage?: boolean;
};
// ===================================================

const ShopLayout1: FC<ShopLayout1Props> = ({
  navbar,
  children,
  hasSearch = pcwEvent.isEventOnGoing ? false : true,
  title = "",
  onClickNavigateToCartPage = false,
}) => {
  const width = useWindowSize();
  const isMobile = useIsMobile();
  const { pathname } = useRouter();

  const { mainCategories } = useMainCategory();

  const { setOpacity, lastScrollY, scrolling, setScrolling, handleScroll } =
    useOpacity();

  const [isMobileSearch, toggleIsMobileSearch] = useState<boolean>(false);
  const [isSearchFocus, toggleIsSearchFocus] = useState(false);
  const [currentDrawer, setCurrentDrawer] = useState<string>("");

  const isCheckoutPage = useMemo(() => {
    return pathname === navigationRoutes.checkout;
  }, [pathname]);

  const handleOpenThisDrawer = (d: string) => {
    setCurrentDrawer(d);
  };

  const handleAllClose = () => {
    setCurrentDrawer("");
  };

  const handleSearchClick = () => {
    toggleIsSearchFocus(!isMobileSearch);
    toggleIsMobileSearch(!isMobileSearch);
  };

  // Initial value of width is 0 onLoad even on desktop which would satisfy isMobile
  useEffect(() => {
    // Actively update state dependent of real-time width
    toggleIsMobileSearch(hasSearch && !isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    const timer = setTimeout(() => {
      if (scrolling) {
        setScrolling(false);
        setOpacity(1);
      }
    }, 500);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleScroll, lastScrollY, scrolling]);

  return (
    <Fragment>
      <Head>{title ? <title>{title}</title> : null}</Head>

      <Sticky fixedOn={0}>
        <>
          {isMobile ? null : <DeliveryHeader />}
          <Header
            hasSearch={isMobileSearch}
            onClickNavigateToCartPage={onClickNavigateToCartPage}
            handleSearchClick={handleSearchClick}
            isSearchFocus={isSearchFocus}
          />
          {isMobile || isCheckoutPage ? null : <Topbar />}
        </>
      </Sticky>

      {!navbar ? (
        <div className="section-after-sticky">{children}</div>
      ) : (
        children
      )}

      {isMobile && !isCheckoutPage ? (
        <>
          <MobileNavigationBar handleOpenThisDrawer={handleOpenThisDrawer} />
          <MobileMenuDrawer
            menuOpen={currentDrawer === "menu"}
            handleAllClose={handleAllClose}
            handleOpenThisDrawer={handleOpenThisDrawer}
            toggleIsSearchFocus={toggleIsSearchFocus}
            toggleIsMobileSearch={toggleIsMobileSearch}
          />
          {mainCategories?.map((c, ind) =>
            c ? (
              <div key={ind}>
                <MobileSubCategDrawer
                  slug={c.slug}
                  openDrawer={currentDrawer == c.slug}
                  handleAllClose={handleAllClose}
                  handleOpenThisDrawer={handleOpenThisDrawer}
                />
              </div>
            ) : null
          )}
        </>
      ) : (
        <BranchMenu />
      )}
      {!isCheckoutPage && <Footer />}
    </Fragment>
  );
};

export default ShopLayout1;
